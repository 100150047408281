import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import ActionCardAnimatedIcon from '../../../components/app/Cards/ActionCardAnimatedIcon';
import AutomationCard from '../../../components/app/Cards/AutomationCard';
import Pagination from '../../../components/general/Pagination';
import MultilineSelect from '../../../components/app/Inputs/MultilineSelect';

const CreateModal = (props: any) => {
    const controller = props.controller;

    return (
        <S.modal onMouseDown={() => controller.setCreateModalActive(false)}>
            <S.modalContent onMouseDown={(e) => e.stopPropagation()}>
                <S.modalHeader>
                    <S.modalTitle>Criar Automação</S.modalTitle>
                    <S.closeModal onClick={() => controller.setCreateModalActive(false)}>X</S.closeModal>
                </S.modalHeader>
                <S.modalBody>
                    <S.inputWrapper>
                        <S.inputLabel>Qual o nome da automação?</S.inputLabel>
                        <S.input autoComplete='disable' type='text' value={controller.title} placeholder='Nenhum' onChange={(e: any) => controller.setTitle(() => e.target.value)}/>
                    </S.inputWrapper>
                    <MultilineSelect label='Quando a automação é ativada?' defaultValue={""} options={[
                        { label: 'Em momento nenhum', value: '', function: () => controller.setTrigger('') },
                        { label: 'Quando um formulário é respondido', value: 'FORM_ANSWERED', function: () => controller.setTrigger('FORM_ANSWERED') }
                    ]} />
                    <MultilineSelect label={controller.triggerQuestion[controller.trigger] ?? "Em que?"} defaultValue={""} options={[
                        { label: 'Nenhum', value: '', function: () => controller.setTriggerOn('') },
                        ...controller.triggerOptions ?? []
                    ]} />
                    <MultilineSelect label='O que acontece?' defaultValue={""} options={[
                        { label: 'Nada', value: '', function: () => controller.setAction('') },
                        ...controller.actionBasedOnTriggerOptions ?? []
                    ]} />
                    <MultilineSelect label={controller.actionOnQuestion[controller.action] ?? "Onde?"} defaultValue={""} options={[
                        { label: 'Em lugar nenhum', value: '', function: () => controller.data({}) },
                        ...controller.onActionOptions ?? []
                    ]} />
                </S.modalBody>
                <S.modalFooter>
                    <S.automationBtnText onClick={() => controller.createAutomation()}>Salvar</S.automationBtnText>
                </S.modalFooter>
            </S.modalContent>
        </S.modal>
    );
}


const Automation = (props: defaultProps) => {
    const controller = useController();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Automações' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            {controller.createModalActive ? <CreateModal controller={controller} /> : null}
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent onSearchChange={controller.setSearchText} pageName='Automações' />
                {controller.general.getCompany().status != 'TRIAL' && !controller.general.getCompany().plan.planModules.includes("module_leads") ? <controller.general.NoPermissionComponent permissionDeniedMessage={"O Seu plano não possui acesso a essa funcionalidade, por favor solicite um upgrade"} /> :
                    controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                        <S.operationsArea>
                            <div className='content-cards-wrapper' onClick={() => controller.setCreateModalActive(active => !active)}>
                                <ActionCardAnimatedIcon mainIcon='faGear' pageItem='Lead'>
                                    Criar <br /> Automação
                                </ActionCardAnimatedIcon>
                            </div>
                            {(controller.automations ?? []).map((item: any, index) => {
                                return (
                                    <AutomationCard onOffAutomation={controller.onOffAutomation} deleteAutomation={controller.deleteAutomation} automation={item} index={index} mainIcon='faGear' playAnimation={item.active ? true : false}>
                                        {item.title} < br/> < br/>{item.description} <br /><br /> [{item.active ? 'ATIVADA' : 'DESATIVADA'}]
                                    </AutomationCard>
                                )
                            })}
                        </S.operationsArea>
                    )}
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    );
}

export default Automation;