import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [editModalActive, setEditModalActive] = useState(false);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [vehicles, setVehicles] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [searchText, setSearchText] = useState('');
    const vehicleCreateTemplate = {
        title: 'Veiculo',
        subtitle: 'Criar um novo veículo',
        infos: [
            {
                name: 'Nome interno',
                key: 'name',
                type: 'text',
                value: '',
                disabled: true,
                required: true
            },
            {
                name: 'Tipo de veículo',
                key: 'vehicle_type',
                type: 'select',
                value: '',
                disabled: true,
                required: false,
                selectOptions: [
                    {
                        name: 'Carro',
                        value: 'car'
                    },
                    {
                        name: 'Motocicleta',
                        value: 'motorcycle'
                    },
                    {
                        name: 'Caminhão',
                        value: 'truck'
                    },
                    {
                        name: 'Caminhonete',
                        value: 'pickup_truck'
                    },
                    {
                        name: 'Microônibus',
                        value: 'minibus'
                    },
                    {
                        name: 'Ônibus',
                        value: 'bus'
                    },
                    {
                        name: 'Bicicleta',
                        value: 'bicycle'
                    },
                    {
                        name: 'Ciclomotor',
                        value: 'moped'
                    },
                    {
                        name: 'Triciclo',
                        value: 'tricycle'
                    },
                    {
                        name: 'Quadriciclo',
                        value: 'quadricycle'
                    },
                    {
                        name: 'Reboque ou semi-reboque',
                        value: 'trailer'
                    },
                    {
                        name: 'Carroça',
                        value: 'cart'
                    },
                    {
                        name: 'Carro-de-mão',
                        value: 'wheelbarrow'
                    },
                    {
                        name: 'Camioneta',
                        value: 'van'
                    },
                    {
                        name: 'Utilitário',
                        value: 'utility'
                    },
                    {
                        name: 'Caminhão-trator',
                        value: 'tractor_truck'
                    },
                    {
                        name: 'Trator de rodas',
                        value: 'wheel_tractor'
                    },
                    {
                        name: 'Trator de esteiras',
                        value: 'crawler_tractor'
                    },
                    {
                        name: 'Trator misto',
                        value: 'mixed_tractor'
                    },
                    {
                        name: 'Especial',
                        value: 'special'
                    },
                    {
                        name: 'De coleção',
                        value: 'collection'
                    },
                    {
                        name: 'Bonde',
                        value: 'tram'
                    },
                    {
                        name: 'Charrete',
                        value: 'carriage'
                    },
                    {
                        name: 'Outro',
                        value: 'other'
                    }
                ]
            },
            {
                name: 'Placa',
                key: 'plate',
                type: 'text',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Marca',
                key: 'vehicle_brand',
                type: 'text',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Modelo',
                key: 'vehicle_model',
                type: 'text',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Ano de fabricação',
                key: 'manufacture_year',
                type: 'number',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Consumo de combustível (Km/L)',
                key: 'fuel_consumption',
                type: 'text',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Cor',
                key: 'color',
                type: 'select',
                value: '',
                disabled: true,
                required: false,
                selectOptions: [
                    {
                        name: 'Amarelo',
                        value: '01'
                    },
                    {
                        name: 'Azul',
                        value: '02'
                    },
                    {
                        name: 'Bege',
                        value: '03'
                    },
                    {
                        name: 'Branco',
                        value: '04'
                    },
                    {
                        name: 'Cinza',
                        value: '05'
                    },
                    {
                        name: 'Dourado',
                        value: '06'
                    },
                    {
                        name: 'Grená',
                        value: '07'
                    },
                    {
                        name: 'Laranja',
                        value: '08'
                    },
                    {
                        name: 'Marrom',
                        value: '09'
                    },
                    {
                        name: 'Prata',
                        value: '10'
                    },
                    {
                        name: 'Preto',
                        value: '11'
                    },
                    {
                        name: 'Rosa',
                        value: '12'
                    },
                    {
                        name: 'Roxo',
                        value: '13'
                    },
                    {
                        name: 'Verde',
                        value: '14'
                    },
                    {
                        name: 'Vermelho',
                        value: '15'
                    },
                    {
                        name: 'Fantasia',
                        value: '16'
                    },
                    {
                        name: 'Outra',
                        value: '17'
                    }
                ]
            },
            {
                name: 'Chassi',
                key: 'chassis_number',
                type: 'text',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Número de registro',
                key: 'register_number',
                type: 'text',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Apólice de seguro',
                key: 'insurance_policy',
                type: 'text',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Despesas de manutenção',
                key: 'maintenance_expenses',
                type: 'money',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Despesas de combustível',
                key: 'fuel_expenses',
                type: 'number',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Despesas de pedágio',
                key: 'toll_expenses',
                type: 'money',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Data de aquisição',
                key: 'acquisition_date',
                type: 'text',
                mask: '##/##/####',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Data de vencimento do licenciamento',
                key: 'licensing_due_date',
                type: 'text',
                mask: '##/##/####',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Data de vencimento do seguro',
                key: 'insurance_due_date',
                type: 'text',
                mask: '##/##/####',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Valor de compra',
                key: 'purchase_value',
                type: 'money',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Valor atual',
                key: 'current_value',
                type: 'money',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Status',
                key: 'status',
                type: 'select',
                value: '',
                disabled: true,
                required: false,
                selectOptions: [
                    {
                        name: 'Ativo',
                        value: 'active'
                    },
                    {
                        name: 'Em manutenção',
                        value: 'maintenance'
                    },
                    {
                        name: 'Vendido',
                        value: 'sold'
                    },
                    {
                        name: 'Inativo',
                        value: 'inactive'
                    }
                ]
            },
        ]
    }

    const getVehicles = async (showLoader?: boolean) => {
        try{
            showLoader == true && setLoading(true);
            const response = await general.api.post('vehicle/list', {
                page: page,
                search: searchText
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setVehicles(response.data.data.data);
                setLoading(false);
            }
        }
        catch(err) {
            console.log(err);
            general.notify('Erro ao buscar veículos', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        getVehicles(true);
    }, [page]);
    
    useEffect(() => {
        getVehicles(true);
    }, []);

    useEffect(() => {
        getVehicles(false);
    }, [createModalActive, editModalActive]);

    useEffect(() => {
        getVehicles(false);
    }, [searchText]);

    return {
        general,
        editModalActive,
        setEditModalActive,
        createModalActive,
        setCreateModalActive,
        vehicleCreateTemplate,
        currentItem,
        setCurrentItem,
        vehicles,
        page,
        setPage,
        totalPages,
        loading,
        permissionBlock,
        searchText,
        setSearchText,
    }
}

export default useController;