import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [categories, setCategories] = useState([]);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [editModalActive, setEditModalActive] = useState(false);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [searchText, setSearchText] = useState('');
    const categoryCreateTemplate = {
        title: 'Nova Categoria',
        subtitle: 'Crie uma nova categoria de produto ou serviço',
        infos: [
            {
                key: 'name',
                name: 'Nome',
                type: "text",
                value: '',
                disabled: true
            },
            {
                key: 'description',
                name: 'Descrição',
                type: "text",
                value: '',
                disabled: true
            },
            {
                key: 'item_type',
                name: 'Categoria',
                type: "select",
                value: '',
                disabled: true,
                selectOptions: [
                    {
                        name: 'Produto',
                        value: '1'
                    },
                    {
                        name: 'Serviço',
                        value: '2'
                    }
                ]
            },
        ]
    }

    const getCategories = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('item-category/list', {
                page: page,
                search: searchText
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setCategories(response.data.data.data);
                setLoading(false);
            }
        }
        catch (e) {
            setLoading(false);
            general.notify('Erro ao buscar categorias', 'error');
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            newCurrentItem.infos = newCurrentItem.infos.map((info: any) => {
                if (info.type == 'select') {
                    info.selectOptions = [
                        {
                            name: 'Produto',
                            value: '1'
                        },
                        {
                            name: 'Serviço',
                            value: '2'
                        }
                    ]
                }
                return info;
            })
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        getCategories(true);
    }, [page]);

    useEffect(() => {
        getCategories(true);
    }, []);

    useEffect(() => {
        getCategories(false);
    }, [createModalActive, editModalActive]);

    useEffect(() => {
        getCategories(false);
    }, [searchText]);

    return {
        general,
        categories,
        setCategories,
        createModalActive,
        setCreateModalActive,
        editModalActive,
        setEditModalActive,
        categoryCreateTemplate,
        currentItem,
        setCurrentItem,
        page,
        setPage,
        totalPages,
        loading,
        permissionBlock,
        searchText,
        setSearchText,
    }
}

export default useController;