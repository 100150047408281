import moment from 'moment';
import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [goals, setGoals] = useState<any>([]);
    const [teams, setTeams] = useState<any>([]);
    const [collaborators, setCollaborators] = useState<any>([]);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [editModalActive, setEditModalActive] = useState(false);
    const [deleteGoalModalActive, setDeleteGoalModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>({
        goal: 0,
    });
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [modalType, setModalType] = useState<'create' | 'edit'>('create');
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [goalForOptions, setGoalForOptions] = useState<{
        value: string,
        option: 'company' | 'team' | 'person',
        title: string
    }[]>([]);

    const defaultGoalForOptions = [
        {
            value: general.getAccount().email ?? '',
            option: 'person',
            title: 'Para mim'
        },
        {
            value: general.getCompany()._id ?? '',
            option: 'company',
            title: 'Para empresa'
        }
    ];

    const getGoals = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('goal/list', {
                page: page,
                getCompleted: false,
                search: searchText
            });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setGoals(response.data.data.data);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar metas', 'error');
            setLoading(false);
        }
    }

    const getTeams = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('teams/list', {
                page: page
            });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setTeams(response.data.data.data);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar serviços', 'error');
            setLoading(false);
        }
    }

    const getCollaborators = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('collaborator/list', {
                page: page,
                limit: 99999999999999
            });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setCollaborators(response.data.data.data);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar colaboradores', 'error');
            setLoading(false);
        }
    }

    const validateRequest = () => {
        if (!currentItem.option?.value) {
            general.notify('Selecione um destinatário', 'error');
            return false;
        }
        if (!currentItem.goal) {
            general.notify('Digite uma meta', 'error');
            return false;
        }
        if (!currentItem.title) {
            general.notify('Digite um título', 'error');
            return false;
        }
        return true;
    }


    const updateGoalForOptions = () => {
        getCollaborators();
        getTeams();
        setGoalForOptions([
            ...defaultGoalForOptions,
            ...collaborators.map((collaborator: any) => {
                return {
                    value: collaborator.email,
                    option: 'person',
                    title: collaborator.name
                }
            }),
            ...teams.map((team: any) => {
                return {
                    value: team._id,
                    option: 'team',
                    title: team.name + ' [Time]'
                }
            })
        ]);
    }

    const requestBuildHelper = (sendObject: any) => {
        sendObject.person_email = null;
        sendObject.team_id = null;
        sendObject.for_company = false;

        if (sendObject?.option?.option == 'team') {
            sendObject.team_id = sendObject.option?.value;
        }
        if (sendObject?.option?.option == 'person') {
            sendObject.person_email = sendObject.option?.value;
        }
        if (sendObject?.option?.option == 'company') {
            sendObject.company_id = sendObject.option?.value;
            sendObject.for_company = true;
        }
        return sendObject;
    }

    const createGoal = async () => {
        if (!validateRequest()) {
            return;
        }
        try {
            let sendObject = currentItem;
            sendObject = requestBuildHelper(sendObject);

            const response = await general.api.post('goal/create', sendObject);
            if (response.status === 200) {
                general.notify('Meta criada com sucesso', 'success');
                setCreateModalActive(false);
                getGoals();
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao criar meta', 'error');
        }
    }

    const editGoal = async () => {
        if (!validateRequest()) {
            return;
        }
        try {
            let sendObject = currentItem;
            sendObject = requestBuildHelper(sendObject);

            const response = await general.api.post(`goal/update/${currentItem._id}`, sendObject);
            if (response.status === 200) {
                general.notify('Meta editada com sucesso', 'success');
                setEditModalActive(false);
                getGoals();
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao editar meta', 'error');
        }
    }

    const editGoalAmount = async (item: any, amount: number) => {
        try {
            const response = await general.api.post(`goal/editcurrentgoalamount/${item._id}`, {
                amount
            });
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao editar meta', 'error');
        }
    }

    const increaseGoal = (item: any) => {
        editGoalAmount(item, item.current + item.modify_in);
        setCurrentItem(item);
        setGoals(goals.map((goal: any) => {
            if (goal._id == item._id) {
                return { ...goal, current: item.current + item.modify_in };
            }
            return goal;
        }));
    }

    const decreaseGoal = (item: any) => {
        setCurrentItem(item);
        editGoalAmount(item, item.current - item.modify_in);
        setGoals(goals.map((goal: any) => {
            if (goal._id == item._id) {
                return { ...goal, current: item.current - item.modify_in };
            }
            return goal;
        }));
    }

    const reset = () => {
        setCreateModalActive(false);
        setEditModalActive(false);
        setCurrentItem({
            goal: 0,
        });
    }

    const completeGoal = async () => {
        try {
            const response = await general.api.post(`goal/update/${currentItem._id}`, {
                completed_at: true
            });
            if (response.status === 200) {
                general.notify('Meta concluída com sucesso', 'success');
                setEditModalActive(false);
                getGoals();
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao concluir meta', 'error');
        }
    }

    const deleteGoal = async () => {
        try {
            const response = await general.api.delete(`goal/delete/${currentItem._id}`);
            if (response.status === 200) {
                general.notify('Meta deletada com sucesso', 'success');
                setEditModalActive(false);
                getGoals();
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao deletar meta', 'error');
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        updateGoalForOptions();
        getGoals(true);
    }, [page]);

    useEffect(() => {
        updateGoalForOptions();
    }, []);

    useEffect(() => {
        getGoals(true);
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setCreateModalActive(false);
                setEditModalActive(false);
            }
        });
    }, []);

    useEffect(() => {
        updateGoalForOptions();
        getGoals(true);
    }, [createModalActive, editModalActive]);

    useEffect(() => {
        updateGoalForOptions();
        getGoals(false);
    }, [searchText]);

    return {
        general,
        goals,
        teams,
        createModalActive,
        editModalActive,
        currentItem,
        page,
        totalPages,
        loading,
        permissionBlock,
        goalForOptions,
        modalType,
        deleteGoalModalActive,
        searchText,
        setSearchText,
        setDeleteGoalModalActive,
        setGoalForOptions,
        setCreateModalActive,
        setEditModalActive,
        setCurrentItem,
        setPage,
        setModalType,
        createGoal,
        editGoal,
        increaseGoal,
        decreaseGoal,
        completeGoal,
        deleteGoal
    }
}

export default useController;