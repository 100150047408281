import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [createModalActive, setCreateModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [automations, setAutomations] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [title, setTitle] = useState('');
    const [searchText, setSearchText] = useState('');

    /* automation items */
    const [trigger, setTrigger] = useState('');
    const [triggerOn, setTriggerOn] = useState('');
    const [action, setAction] = useState<any>('');
    const [data, setData] = useState<any>();

    const [triggerOptions, setTriggerOptions] = useState<any>([]);
    const [onActionOptions, setOnActionOptions] = useState<any>([]);
    const [actionBasedOnTriggerOptions, setActionBasedOnTriggerOptions] = useState<any>([]);

    const triggerQuestion = {
        "FORM_ANSWERED": "Qual formulário?",
    }

    const actionOnQuestion = {
        "CREATE_CARD": "Onde o cartão será criado?",
    }

    const clearAll = () => {
        setTriggerOn('');
        setAction('');
        setData({});
        setTriggerOptions([]);
        setOnActionOptions([]);
        setActionBasedOnTriggerOptions([]);
        setTitle('');
    }

    const getAutomations = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('automation/list', {
                page: page,
                search: searchText
            });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setAutomations(response.data.data.data);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar automações', 'error');
            setLoading(false);
        }
    }

    const getTriggerOptions = async (trigger: string) => {
        if (trigger == '') {
            clearAll()
            return
        }
        if (trigger == 'FORM_ANSWERED') {
            try {
                const response = await general.api.post('form/list', {});
                if (response.status === 200) {
                    setTriggerOptions(response.data.data.data?.map((form: any) => {
                        return {
                            value: form._id,
                            label: form.title,
                            function: () => { setTriggerOn(() => form._id) }
                        }
                    }));
                }
            }
            catch (err) {
                console.log(err);
                general.notify('Erro ao buscar formulários', 'error');
            }
        }
    }

    const getOnActionOptions = async (action: string) => {
        if (action == '') {
            setOnActionOptions([]);
            return
        };
        if (action == 'CREATE_CARD') {
            try {
                const response = await general.api.post('flow/list', {});
                if (response.status === 200) {
                    let onActionOptions: any = [];
                    response.data.data.data?.map((flow: any) => {
                        flow.columns?.map((column: any) => {
                            onActionOptions.push({
                                value: column._id,
                                label: `Fluxo "${flow.name}" - Coluna "${column.name}"`,
                                function: () => {
                                    setData(() => ({
                                        column_id: column._id,
                                    }))
                                }
                            });
                        });
                    })
                    setOnActionOptions(() => onActionOptions ?? []);
                }
            }
            catch (err) {
                console.log(err);
                general.notify('Erro ao buscar cards', 'error');
            }
        }
    }

    const getActionBasedOnTriggerOptions = async (trigger: string) => {
        if (trigger == '') {
            setActionBasedOnTriggerOptions([]);
            return
        }
        if (trigger == 'FORM_ANSWERED') {
            setActionBasedOnTriggerOptions([
                {
                    value: 'CREATE_CARD',
                    label: 'Cria cartão em um fluxo',
                    function: () => { setAction(() => 'CREATE_CARD') }
                }/* 
                {
                    value: 'CREATE_NOTIFICATION',
                    label: 'Criar notificação',
                    function: setAction('CREATE_NOTIFICATION')
                } */
            ]);
        }
    }

    const validateData = () => {
        let valid = true;
        if (title == '') {
            general.notify('Dê um nome para a automação', 'error');
            return false;
        }
        if (trigger == '') {
            general.notify('Selecione um gatilho', 'error');
            return false;
        }
        if (action == '') {
            general.notify('Selecione uma ação', 'error');
            return false;
        }
        if (trigger == 'FORM_ANSWERED' && triggerOn == '') {
            general.notify('Selecione um formulário', 'error');
            return false;
        }
        if (action == 'CREATE_CARD' && !data.column_id) {
            general.notify('Selecione uma coluna', 'error');
            return false;
        }
        return valid;
    }

    const getAutomationDescription = () => {
        let description = '';
        if (trigger == 'FORM_ANSWERED') {
            description = `Quando o formulário "${triggerOptions.filter((trigger: any) => trigger.value == triggerOn)[0]?.label ?? "Não informado"}" recebe uma resposta, um card no ${onActionOptions.filter((action: any) => action.value == data.column_id)[0]?.label?.replace(' -', ',') ?? "Não informado"} é criado com as informações do formulário.`
        }
        return description;
    }

    const createAutomation = async () => {
        if (!validateData()) return;

        let automationDescription = getAutomationDescription() ?? 'Não informado';

        try {
            const response = await general.api.post('automation/create', {
                title: title,
                trigger: trigger,
                triggerOn: triggerOn,
                actions: [{
                    action: action,
                    data: data
                }],
                description: automationDescription ?? 'Não informado',
                active: true,
                uses: 0
            });
            if (response.status === 200) {
                if (response.data.status == 500) {
                    general.notify(response.data.message, 'error');
                    return;
                }
                if (response.data.status == 403) {
                    general.notify(response.data.message, 'error');
                    return;
                }
                general.notify('Automação criada com sucesso', 'success');
                setCreateModalActive(false);
                getAutomations(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao criar automação', 'error');
        }
    }

    const deleteAutomation = async (id: string) => {
        try {
            const response = await general.api.delete(`automation/delete/${id}`);
            if (response.status === 200) {
                if (response.data.status == 403) {
                    general.notify(response.data.message, 'error');
                    return;
                }
                general.notify('Automação deletada com sucesso', 'success');
                getAutomations(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao deletar automação', 'error');
        }
    }

    const onOffAutomation = async (id: string, active: boolean) => {
        try {
            const response = await general.api.post(`automation/update/${id}`, {
                active: active
            });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    general.notify(response.data.message, 'error');
                    return;
                }
                general.notify('Automação ' + (active ? 'ativada' : 'desativada') + ' com sucesso', 'success');
                getAutomations(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao ativar/desativar automação', 'error');
        }
    }


    useEffect(() => {
        getTriggerOptions(trigger);
        getActionBasedOnTriggerOptions(trigger);
    }, [trigger]);

    useEffect(() => {
        getOnActionOptions(action);
    }, [action]);


    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        getAutomations(true);
    }, [page]);

    useEffect(() => {
        getAutomations(true);
    }, []);

    useEffect(() => {
        getAutomations(false);
    }, [createModalActive]);

    useEffect(() => {
        getAutomations(false);
    }, [searchText]);

    return {
        general,
        createModalActive,
        setCreateModalActive,
        currentItem,
        setCurrentItem,
        automations,
        page,
        setPage,
        getAutomations,
        trigger,
        setTrigger,
        triggerOn,
        setTriggerOn,
        action,
        setAction,
        data,
        setData,
        triggerOptions,
        onActionOptions,
        getTriggerOptions,
        getOnActionOptions,
        getActionBasedOnTriggerOptions,
        actionBasedOnTriggerOptions,
        totalPages,
        loading,
        permissionBlock,
        triggerQuestion,
        actionOnQuestion,
        createAutomation,
        title,
        setTitle,
        deleteAutomation,
        onOffAutomation,
        searchText,
        setSearchText
    }
}

export default useController;