import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [teams, setTeams] = useState<any>([]);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [editModalActive, setEditModalActive] = useState(false);
    const [deleteTeamModalActive, setDeleteTeamModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [collaborators, setCollaborators] = useState<any>([]);
    const [modalType, setModalType] = useState<'create' | 'edit'>('create');
    const [searchText, setSearchText] = useState('');
    const [toAddCollaborator, setToAddCollaborator] = useState<any>({
        collaborator_email: '',
        collaborator_name: '',
        collaborator_role: ''
    });

    const getTeams = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('teams/list', {
                page: page,
                search: searchText
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setTeams(response.data.data.data);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar serviços', 'error');
            setLoading(false);
        }
    }

    const setCollaboratorNameAndEmail = (collaborator: any) => {
        setToAddCollaborator({
            collaborator_email: collaborator.value.email,
            collaborator_name: general.formatName(collaborator.value.name),
            collaborator_role: ''
        });
    }

    const clearCollaboratorToAdd = () => {
        setToAddCollaborator(null);
    }

    const getCollaborators = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('collaborator/list', {
                page: page,
                limit: 99999999999999
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setCollaborators([...response.data.data.data]);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar colaboradores', 'error');
            setLoading(false);
        }
    }

    const createEditTeam = async () => {
        if(!currentItem) {
            general.notify('Preencha os campos', 'error');
            return;
        }
        if(!currentItem.name) {
            general.notify('Digite um nome para o time', 'error');
            return;
        }
        if(!currentItem.members || currentItem.members?.length === 0) {
            general.notify('Adicione pelo menos um membro ao time', 'error');
            return
        }
        try {
            setLoading(true);
            const response = await general.api.post(`teams/${modalType == 'create' ? 'create' : `update/${currentItem._id}`}`, {
                name: currentItem.name,
                members: currentItem.members
            })
            setLoading(false);
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    general.notify('Você não tem permissão para fazer isso', 'error');
                    return;
                }
                general.notify(`Time ${modalType == 'create' ? 'criado' : 'atualizado'} com sucesso`, 'success');
                setCreateModalActive(false);
                setEditModalActive(false);
                clearCollaboratorToAdd();
                setDeleteTeamModalActive(false)
                setCurrentItem(null);
                getTeams(true);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao criar time', 'error');
            setLoading(false);
        }
    }

    const addCollaborator = () => {
        if (!toAddCollaborator.collaborator_email) {
            general.notify('Selecione um colaborador', 'error');
            return;
        }
        if (!toAddCollaborator.collaborator_role) {
            general.notify('Selecione um cargo', 'error');
            return;
        }
        let newCurrentItem = currentItem ?? {
            name: '',
            members: []
        };
        setCurrentItem({...newCurrentItem, members: [...newCurrentItem.members ?? [], toAddCollaborator]});
        clearCollaboratorToAdd();
    }

    const deleteTeamMember = (id: string) => {
        setCurrentItem({...currentItem, members: currentItem.members.filter((member: any) => member.collaborator_email !== id)});
        general.notify('Membro do time excluido com sucesso!', 'success');
    }

    const deleteTeam = async (id: string) => {
        try {
            setLoading(true);
            const response = await general.api.delete(`teams/delete/${id}`);
            setLoading(false);
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    general.notify('Você não tem permissão para fazer isso', 'error');
                    return;
                }
                general.notify('Time excluído com sucesso', 'success');
                getTeams(true);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao excluir time', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setCreateModalActive(false);
                setEditModalActive(false);
                setDeleteTeamModalActive(false);
                clearCollaboratorToAdd();
                setCurrentItem(null);
                getTeams(true);
            }
        });
    }, []);

    useEffect(() => {
        getTeams(true);
    }, [page]);

    useEffect(() => {
        getCollaborators(true);
        getTeams(true);
    }, []);

    useEffect(() => {
        getTeams(false);
    }, [createModalActive, editModalActive]);

    useEffect(() => {
        getTeams(false);
    }, [searchText]);

    return {
        general,
        teams,
        createModalActive,
        editModalActive,
        currentItem,
        page,
        totalPages,
        loading,
        permissionBlock,
        collaborators,
        toAddCollaborator,
        modalType,
        deleteTeamModalActive,
        searchText,
        setCreateModalActive,
        setEditModalActive,
        setCurrentItem,
        setPage,
        setToAddCollaborator,
        setCollaboratorNameAndEmail,
        clearCollaboratorToAdd,
        addCollaborator,
        createEditTeam,
        setModalType,
        deleteTeamMember,
        deleteTeam,
        setDeleteTeamModalActive,
        setSearchText
    }
}

export default useController;