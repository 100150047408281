import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [editModalActive, setEditModalActive] = useState(false);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [stakeholders, setStakeholders] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [searchText, setSearchText] = useState('');
    const personCreateTemplate = {
        title: 'Cliente',
        subtitle: 'Criar um novo cliente',
        infos: [
            { key: 'name', type: "text", name: 'Nome', value: '', disabled: true },
            { key: 'email', type: "text", name: 'E-mail', value: '', disabled: true },
            { key: 'phone', type: "phone", name: 'Telefone', value: '', disabled: true },
            { key: 'document', type: "text", name: 'Documento', value: '', disabled: true },
        ]
    }

    const getStakeholders = async (showLoader?: boolean) => {
        try{
            showLoader == true && setLoading(true);
            const response = await general.api.post('stakeholder/list', {
                page: page,
                type: 1,
                search: searchText
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setStakeholders(response.data.data.data);
                setLoading(false);
            }
        }
        catch(err) {
            console.log(err);
            general.notify('Erro ao buscar clientes', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        getStakeholders(true);
    }, [page]);
    
    useEffect(() => {
        getStakeholders(true);
    }, []);

    useEffect(() => {
        getStakeholders(false);
    }, [createModalActive, editModalActive]);

    useEffect(() => {
        getStakeholders(false);
    }, [searchText]);

    return {
        general,
        editModalActive,
        setEditModalActive,
        createModalActive,
        setCreateModalActive,
        personCreateTemplate,
        currentItem,
        setCurrentItem,
        stakeholders,
        page,
        setPage,
        totalPages,
        loading,
        permissionBlock,
        searchText,
        setSearchText,
    }
}

export default useController;