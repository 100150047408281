import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [products, setProducts] = useState<any>([]);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [editModalActive, setEditModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [categories, setCategories] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [productCreateTemplate, setProductCreateTemplate] = useState({
        title: 'Novo Produto',
        subtitle: 'Crie um novo produto',
        infos: [
            {
                key: 'name',
                name: 'Nome',
                type: "text",
                value: '',
                disabled: true,
                required: true
            },
            {
                key: 'buy_price',
                name: 'Valor de Compra',
                type: "money",
                value: '0,00',
                disabled: true,
                required: false
            },
            {
                key: 'price',
                name: 'Valor de Venda',
                type: "money",
                value: '0,00',
                disabled: true,
                required: true
            },
            {
                key: 'stock',
                name: 'Estoque',
                type: "number",
                value: '1',
                disabled: true,
                required: true
            },
            {
                key: 'description',
                name: 'Descrição',
                type: "text",
                value: '',
                disabled: true
            },
            {
                key: 'discount',
                name: 'Desconto',
                type: "number",
                value: '',
                disabled: true
            },
            {
                key: 'sells',
                name: 'Vendas',
                type: "number",
                value: '0',
                disabled: true
            },
            {
                key: 'observation',
                name: 'Observação',
                type: "text",
                value: '',
                disabled: true
            },
            {
                key: 'barcode',
                name: 'Código de Barras',
                type: "text",
                value: '',
                disabled: true
            },
            {
                key: 'cfop',
                name: 'CFOP',
                type: "text",
                value: '',
                disabled: true
            },
            {
                key: 'ncm',
                name: 'NCM',
                type: "text",
                value: '',
                disabled: true
            },
            {
                key: 'origin_icms',
                name: 'ICMS Origem',
                type: "select",
                value: '0',
                disabled: true,
                selectOptions: [
                    {
                        name: 'Nacional',
                        value: '0'
                    },
                    {
                        name: 'Estrangeira (importação direta)',
                        value: '1'
                    },
                    {
                        name: 'Estrangeira (adquirida no mercado interno)',
                        value: '2'
                    },
                    {
                        name: 'Nacional com mais de 40% de conteúdo estrangeiro',
                        value: '3'
                    },
                    {
                        name: 'Nacional produzida através de processos produtivos básicos',
                        value: '4'
                    },
                    {
                        name: 'Nacional com menos de 40% de conteúdo estrangeiro',
                        value: '5'
                    },
                    {
                        name: 'Estrangeira (importação direta) sem produto nacional similar',
                        value: '6'
                    },
                    {
                        name: 'Estrangeira (adquirida no mercado interno) sem produto nacional similar',
                        value: '7'
                    }
                    
                ]
            },
            {
                key: 'tributary_situation_icms',
                name: 'Situação Tributária ICMS',
                type: "select",
                value: '102',
                disabled: true,
                selectOptions: [
                    {
                        name: 'Tributada pelo Simples Nacional sem permissão de crédito',
                        value: '102'
                    },
                    {
                        name: 'Imune',
                        value: '300'
                    },
                    {
                        name: 'CMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação',
                        value: '500'
                    },
                    {
                        name: 'Tributada integralmente',
                        value: '00'
                    },
                    {
                        name: 'Isenta',
                        value: '40'
                    },
                    {
                        name: 'Não tributada',
                        value: '41'
                    },
                    {
                        name: 'ICMS cobrado anteriormente por substituição tributária',
                        value: '60'
                    }
                    
                ]
            },
            {
                key: 'icms_modality',
                name: 'ICMS Modalidade (Obrigatório se situação = Integralmente Tributada)',
                type: "select",
                value: '',
                disabled: true,
                selectOptions: [
                    {
                        name: 'ICMS Modalidade (Obrigatório se situação = Tributada Integralmente)',
                        value: ''
                    },
                    {
                        name: 'margem de valor agregado (%)',
                        value: '0'
                    },
                    {
                        name: 'pauta (valor)',
                        value: '1'
                    },
                    {
                        name: 'preço tabelado máximo (valor)',
                        value: '2'
                    },
                    {
                        name: 'valor da operação',
                        value: '3'
                    }
                    
                ]
            },
            {
                key: 'category_id',
                name: 'Categoria',
                type: "select",
                value: '',
                disabled: true,
                required: true,
                selectOptions: []
            },
        ]
    });

    const getProducts = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('product/list', {
                page: page,
                search: searchText
            });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setProducts(response.data.data.data);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar produtos', 'error');
            setLoading(false);
        }
    }

    const getCategories = async () => {
        try {
            const response = await general.api.get('item-category/list/1');
            if (response.status === 200) {
                if (response.data.status == 403) {
                    return;
                }
                let categories = response.data.data.map((item: any) => {
                    return {
                        name: item.name,
                        value: item._id
                    }
                });
                setCategories(categories);
                setProductCreateTemplate(currentTemplate => {
                    return {
                        ...currentTemplate,
                        infos: currentTemplate.infos.map((info: any) => {
                            if (info.key == 'category_id') {
                                info.selectOptions = categories;
                            }
                            return info;
                        })
                    }
                })
                return categories;
            }
        }
        catch (e) {
            general.notify('Erro ao buscar categorias', 'error');
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            newCurrentItem.infos = newCurrentItem.infos.map((info: any) => {
                if (info.key == 'category_id') {
                    info.selectOptions = categories;
                }
                return info;
            })
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        getProducts(true);
    }, [page]);

    useEffect(() => {
        getProducts(true);
        getCategories();
    }, []);

    useEffect(() => {
        getProducts(false);
        getCategories();
    }, [createModalActive, editModalActive]);

    useEffect(() => {
        getProducts(false);
    }, [searchText]);

    return {
        general,
        products,
        setProducts,
        createModalActive,
        setCreateModalActive,
        editModalActive,
        setEditModalActive,
        productCreateTemplate,
        currentItem,
        setCurrentItem,
        page,
        setPage,
        totalPages,
        loading,
        permissionBlock,
        searchText,
        setSearchText
    }
}

export default useController;