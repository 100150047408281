import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [editModalActive, setEditModalActive] = useState(false);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [flows, setFlows] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [searchText, setSearchText] = useState('');
    const flowCreateTemplate = {
        title: 'Fluxo',
        subtitle: 'Criar um novo flow',
        infos: [
            { key: 'name', type: "text", name: 'Nome', value: '', disabled: true },
            { key: 'description', type: "text", name: 'Descrição', value: '', disabled: true },
        ]
    }

    const getFlows = async (showLoader?: boolean) => {
        try{
            showLoader == true && setLoading(true);
            const response = await general.api.post('flow/list', {
                page: page,
                search: searchText
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setFlows(response.data.data.data);
                setLoading(false);
            }
        }
        catch(err) {
            console.log(err);
            general.notify('Erro ao buscar clientes', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        getFlows(true);
    }, [page]);
    
    useEffect(() => {
        getFlows(true);
    }, []);

    useEffect(() => {
        getFlows(false);
    }, [createModalActive, editModalActive]);

    useEffect(() => {
        getFlows(false);
    }, [searchText]);

    return {
        general,
        editModalActive,
        setEditModalActive,
        createModalActive,
        setCreateModalActive,
        flowCreateTemplate,
        currentItem,
        setCurrentItem,
        flows,
        page,
        setPage,
        totalPages,
        loading,
        permissionBlock,
        searchText,
        setSearchText,
    }
}

export default useController;