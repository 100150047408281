import general from '../../../../utils/general';
import { useState, useEffect } from 'react';
import moment from 'moment';

export const useController = () => {
    const [editModalActive, setEditModalActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const [codeModalActive, setCodeModalActive] = useState(false);
    const [flowcolumnDeleted, setFlowColumnDeleted] = useState(false);
    const [codeForIntegration, setCodeForIntegration] = useState('');
    const [initialLoad, setInitialLoad] = useState(false);
    const [flowColumns, setFlowColumns] = useState<any[]>([]);
    const [cardCreateRequest, setCardCreateRequest] = useState(false);
    const [columnNoDelay, setColumnNoDelay] = useState(false);
    const [flow, setFlow] = useState<any>(null);
    const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);
    const [hoveredColumnId, setHoveredColumnId] = useState<string | null>(null);
    const [draggedCardIndex, setDraggedCardIndex] = useState<number | null>(null);
    const [draggedCardId, setDraggedCardId] = useState<string | null>(null);
    const [draggedFromColumnId, setDraggedFromColumnId] = useState<string | null>(null);
    const [draggedColumnIndex, setDraggedColumnIndex] = useState<any | null>(null);
    const [addColumnModalActive, setAddColumnModalActive] = useState(false);
    const [randomToCloseEverything, setRandomToCloseEverything] = useState<any>(0);
    const [editFlowModalOpen, setEditFlowModalOpen] = useState(false);
    const [AIAllowed, setAIAllowed] = useState<boolean>(false)
    const [loadingAIAnswer, setLoadingAIAnswer] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')

    const getFlowColumnFromId = async (showLoader?: boolean) => {
        try {
            const _id = window.location.pathname.split('/').pop();
            showLoader == true && setLoading(true);
            const response = await general.api.get('flow/column/get/' + _id);
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setFlow(response.data.data.flow);
                setFlowColumns(response.data.data.flowColumns);
                setLoading(false);
            }
            setInitialLoad(true);
        }
        catch (err) {
            console.log(err);
            setInitialLoad(true);
            general.notify('Erro ao buscar colunas', 'error');
            setLoading(false);
        }
    }

    const updateFlow = async (data: any) => {
        try {
            const _id = flow._id;
            const response = await general.api.post('flow/update/' + _id, data);
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    return;
                }
                general.notify('Fluxo atualizado com sucesso', 'success');
                getFlowColumnFromId(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao atualizar fluxo', 'error');
        }
    }

    const deleteFlow = async () => {
        try {
            const _id = flow._id;
            const response = await general.api.delete('flow/delete/' + _id);
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    return;
                }
                general.notify('Fluxo deletado com sucesso', 'success');
                general.GoToRoute('/app/flows');
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao deletar fluxo', 'error');
        }
    }

    const createColumn = async (name: string) => {
        try {
            const _id = flow._id;
            setColumnNoDelay(true);
            const response = await general.api.post('flow/column/create', { name, flow_id: _id });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                general.notify('Coluna criada com sucesso', 'success');
                setFlowColumns([...flowColumns, response.data.data]);
                getFlowColumnFromId(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao criar coluna', 'error');
            setLoading(false);
        }
    }

    const updateColumn = async (name: string, _id: string) => {
        try {
            const response = await general.api.post(`flow/column/update/${_id}`, { name });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    return;
                }
                general.notify('Coluna atualizada com sucesso', 'success');
                getFlowColumnFromId();
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao atualizar coluna', 'error');
        }
    }

    const deleteColumn = async (_id: string) => {
        try {
            setFlowColumns(flowColumns.filter(column => column._id != _id));
            const response = await general.api.delete(`flow/column/delete/${_id}`);
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    return;
                }
                general.notify('Coluna deletada com sucesso', 'success');
                setFlowColumnDeleted(true);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao deletar coluna', 'error');
        }
    }

    const deleteAllCardsFromColumn = async (columnId: string) => {
        try {
            setFlowColumns(flowColumns.map(column => {
                if (column._id === columnId) {
                    column.cards = [];
                }
                return column;
            }));
            const response = await general.api.post(`flow/column/update/${columnId}`, { cards: [] });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    return;
                }
                general.notify('Cartões deletados com sucesso', 'success');
                getFlowColumnFromId(false);
            }
        } catch (err) {
            console.log(err);
            general.notify('Erro ao deletar cartões', 'error');
        }
    }

    const duplicateColumn = async (_id: string) => {
        try {
            const response = await general.api.post(`flow/column/create`, {
                name: 'Cópia de ' + flowColumns.find(column => column._id == _id).name,
                flow_id: flow._id,
                cards: flowColumns.find(column => column._id == _id).cards
            });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    return;
                }
                general.notify('Coluna duplicada com sucesso', 'success');
                getFlowColumnFromId();
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao duplicar coluna', 'error');
        }
    }

    const updateColumnCards = async (cards: any[], column_id: string) => {
        try {
            setFlowColumns(flowColumns.map(column => {
                if (column._id == column_id) {
                    column.cards = cards;
                }
                return column;
            }));
            const response = await general.api.post(`flow/column/update/${column_id}`, { cards });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    return;
                }
                general.notify('Cartões atualizados com sucesso', 'success');
                getFlowColumnFromId(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao atualizar cartões', 'error');
        }
    }


    const addCard = async (column_id: string, name: string) => {
        try {
            setCardCreateRequest(true);
            setColumnNoDelay(true);
            setFlowColumns(flowColumns.map(column => {
                if (column._id == column_id) {
                    column.cards.push({
                        _id: general.randomString(24),
                        name,
                        created_by: {
                            name: general.getAccount().name,
                            _id: general.getAccount()._id
                        },
                        position: column.cards.length,
                        enteredColumnAt: moment().toISOString(),
                        createdAt: moment().toISOString(),
                        updatedAt: moment().toISOString()
                    });
                }
                return column;
            }));

            const response = await general.api.post(`flow/column/update/${column_id}`, { cards: flowColumns.find(column => column._id == column_id).cards });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    return;
                }
                general.notify('Cartão criado com sucesso', 'success');
                getFlowColumnFromId(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao criar cartão', 'error');
        }
    }

    const addMultipleCards = async (column_id: string, cards: string[]) => {
        try {
            setColumnNoDelay(true);
            setFlowColumns(flowColumns.map(column => {
                if (column._id == column_id) {
                    cards.map((name: string) => {
                        column.cards.push({
                            _id: general.randomString(24),
                            name,
                            created_by: {
                                name: general.getAccount().name,
                                _id: general.getAccount()._id
                            },
                            position: column.cards.length,
                            enteredColumnAt: moment().toISOString(),
                            createdAt: moment().toISOString(),
                            updatedAt: moment().toISOString()
                        });
                    })
                }
                return column;
            }));

            const response = await general.api.post(`flow/column/update/${column_id}`, { cards: flowColumns.find(column => column._id == column_id).cards });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    return;
                }
                general.notify('Cartões criados com sucesso', 'success');
                getFlowColumnFromId(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao criar cartões', 'error');
        }
    }

    const deleteCard = async (column_id: string, card_id: string) => {
        try {
            setFlowColumns(flowColumns.map(column => {
                if (column._id == column_id) {
                    column.cards = column.cards.filter((card: any) => card._id != card_id);
                }
                return column;
            }));
            const response = await general.api.post(`flow/column/update/${column_id}`, { cards: flowColumns.find(column => column._id == column_id).cards });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    return;
                }
                general.notify('Cartão deletado com sucesso', 'success');
                getFlowColumnFromId(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao deletar cartão', 'error');
        }
    }
    const moveCard = async (cardId: string, fromColumnId: string, toColumnId: string, toIndex: number) => {
        try {
            let card: any;
            let from_column: any;
            let to_column: any;
            setCardCreateRequest(false);
            setFlowColumns(flowColumns.map(column => {
                if (column._id === fromColumnId) {
                    from_column = column;
                    column.cards = column.cards.filter((card: any) => card);
                    card = column.cards.find((card: any) => card._id === cardId);
                    column.cards = column.cards.filter((card: any) => card._id !== cardId);
                }
                return column;
            }));
            setFlowColumns(flowColumns.map(column => {
                if (column._id === toColumnId) {
                    to_column = column;
                    const updatedCards = [...column.cards];
                    updatedCards.splice(toIndex, 0, { ...card, enteredColumnAt: moment().toISOString() });
                    column.cards = column.cards.filter((card: any) => card);
                    column.cards = updatedCards;
                }
                return column;
            }));
            const response = await general.api.post(`flow/column/update/${fromColumnId}`, { cards: from_column.cards });
            if (response.status === 200) {
                const responseAlt = await general.api.post(`flow/column/update/${toColumnId}`, { cards: to_column.cards });
                if (responseAlt.status === 200) {
                    getFlowColumnFromId(false);
                }
            }
        } catch (err) {
            console.log(err);
            general.notify('Erro ao tentar mover', 'error');
        }
    };

    const moveAllCards = async (fromColumnId: string, toColumnId: string) => {
        try {
            let from_column: any;
            let to_column: any;
            let cardsToMove: any;
            setFlowColumns(flowColumns.map(column => {
                if (column._id === fromColumnId) {
                    from_column = column;
                    cardsToMove = column.cards.filter((card: any) => card).map((card: any) => ({ ...card, enteredColumnAt: moment().toISOString() }));
                    column.cards = []
                }
                return column;
            }));
            setFlowColumns(flowColumns.map(column => {
                if (column._id === toColumnId) {
                    to_column = column;
                    column.cards = column.cards.filter((card: any) => card);
                    column.cards = [...column.cards, ...cardsToMove];
                }
                return column;
            }));
            const response = await general.api.post(`flow/column/update/${fromColumnId}`, { cards: from_column.cards });
            if (response.status === 200) {
                const responseAlt = await general.api.post(`flow/column/update/${toColumnId}`, { cards: to_column.cards });
                if (responseAlt.status === 200) {
                    getFlowColumnFromId(false);
                }
            }
        } catch (err) {
            console.log(err);
            general.notify('Erro ao mover cartões', 'error');
        }
    }

    const reorderCard = async (cardId: string, columnId: string, fromIndex: number, toIndex: number) => {
        setFlowColumns(flowColumns.map(column => {
            if (column._id === columnId) {
                const updatedCards = [...column.cards];
                const [movedCard] = updatedCards.splice(fromIndex, 1);
                updatedCards.splice(toIndex, 0, movedCard);
                column.cards = updatedCards;
            }
            return column;
        }));

        const column = flowColumns.find(column => column._id === columnId);
        const response = await general.api.post(`flow/column/update/${columnId}`, { cards: column.cards });
        if (response.status === 200) {
            getFlowColumnFromId(false);
        }
    };

    const updateCard = async (columnId: string, cardId: string, data = {}) => {
        setFlowColumns(flowColumns.map(column => {
            if (column._id === columnId) {
                column.cards = column.cards.map((card: any) => {
                    if (card._id === cardId) {
                        return { ...card, ...data };
                    }
                    return card;
                });
            }
            return column;
        }));
        const column = flowColumns.find(column => column._id === columnId);
        const response = await general.api.post(`flow/column/update/${columnId}`, { cards: column.cards });
        if (response.status === 200) {
            getFlowColumnFromId(false);
            general.notify('Cartão atualizado com sucesso', 'success');
        }
    }

    const duplicateCard = async (columnId: string, cardId: string) => {
        try {
            const column = flowColumns.find(column => column._id === columnId);
            const card = column.cards.find((card: any) => card._id === cardId);
            const index = column.cards.indexOf(card);
            column.cards.splice(index + 1, 0, { ...card, _id: general.randomString(24), name: 'Cópia de ' + card.name, enteredColumnAt: moment().toISOString() });
            setFlowColumns(flowColumns.map(column => {
                if (column._id === columnId) {
                    return { ...column, cards: column.cards };
                }
                return column;
            }));
            const response = await general.api.post(`flow/column/update/${columnId}`, { cards: column.cards });
            if (response.status === 200) {
                getFlowColumnFromId(false);
                general.notify('Cartão duplicado com sucesso', 'success');
            }
        } catch (err) {
            console.log(err);
            general.notify('Erro ao duplicar cartão', 'error');
        }
    }
    const addChecklistItem = async (columnId: string, cardId: string, name: string) => {
        setFlowColumns(flowColumns.map(column => {
            if (column._id === columnId) {
                column.cards = column.cards.map((card: any) => {
                    if (card._id === cardId) {
                        card.checklist = card.checklist || [];
                        card.checklist.push({
                            _id: general.randomString(24),
                            name,
                            finished_at: null
                        });
                    }
                    return card;
                });
            }
            return column;
        }));
        const column = flowColumns.find(column => column._id === columnId.trim());
        const response = await general.api.post(`flow/column/update/${columnId}`, { cards: column.cards });
        if (response.status === 200) {
            getFlowColumnFromId(false);
        }
    }

    const updateChecklistItem = async (columnId: string, cardId: string, checklistId: string, data = {}) => {
        setFlowColumns(flowColumns.map(column => {
            if (column._id === columnId) {
                column.cards = column.cards.map((card: any) => {
                    if (card._id === cardId) {
                        card.checklist = card.checklist.map((item: any) => {
                            if (item._id === checklistId) {
                                return { ...item, ...data };
                            }
                            return item;
                        });
                    }
                    return card;
                });
            }
            return column;
        }));
        const column = flowColumns.find(column => column._id === columnId);
        const response = await general.api.post(`flow/column/update/${columnId}`, { cards: column.cards });
        if (response.status === 200) {
            getFlowColumnFromId(false);
        }
    }

    const deleteChecklistItem = async (columnId: string, cardId: string, checklistId: string) => {
        setFlowColumns(flowColumns.map(column => {
            if (column._id === columnId) {
                column.cards = column.cards.map((card: any) => {
                    if (card._id === cardId) {
                        card.checklist = card.checklist.filter((item: any) => item._id !== checklistId);
                    }
                    return card;
                });
            }
            return column;
        }));
        const column = flowColumns.find(column => column._id === columnId);
        const response = await general.api.post(`flow/column/update/${columnId}`, { cards: column.cards });
        if (response.status === 200) {
            getFlowColumnFromId(false);
        }
    }

    const reorderColumn = async (fromIndex: number, toIndex: number) => {
        try {
            const updatedColumns = [...flowColumns];
            const [movedColumn] = updatedColumns.splice(fromIndex, 1);
            updatedColumns.splice(toIndex, 0, movedColumn);
            setColumnNoDelay(true);
            setCardCreateRequest(true);


            setFlowColumns(updatedColumns);

            const response = await general.api.post(`flow/column/update-multiple`, updatedColumns.map((column, index) => ({ ...column, position: index })));
            if (response.status === 200) {
                general.notify('Colunas reordenadas com sucesso', 'success');
                getFlowColumnFromId(false);
            }
        } catch (err) {
            console.error(err);
            general.notify('Erro ao reordenar colunas', 'error');
        }
    };

    const verifyAI = async () => {
        try {
            const response = await general.api.post('helpers/verify/module', {
                module: 'module_ai'
            })
            if (response.status === 200) {
                if (response.data.status === 403) {
                    setAIAllowed(false)
                    return
                }
                setAIAllowed(true)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getFlowColumnFromId(true);
        verifyAI();
    }, []);

    const makeChecklistWithAI = async (
        cardId: string,
        columnId: string,
        title: string,
        description: string,
        column: string,
    ) => {
        try {
            setLoadingAIAnswer(true)
            let prompt = `
                [noProse]
                Crie uma checklist para o seguinte card de um kanban:
                Regras: separe por ";", não utilize caracteres especiais, não utilize - ou _, não utilize nada que represente uma lista 
                Titulo do Card: ${title}
                Descrição do Card: ${description}
                Coluna: ${column}
                Board: ${flow.name}
            `;
            const response = await general.api.post('helpers/aiprompt', {
                prompt
            })
            if (response.status === 200) {
                if (response.data.status === 403) {
                    setAIAllowed(false)
                    setLoadingAIAnswer(false)
                    general.notify('Você não tem permissão para fazer isso', 'error')
                    return
                }
                if (response.data.data) {
                    let checklist = response.data.data.split(';').map((item: string) => item.trim())
                    checklist.map((item: string) => {
                        addChecklistItem(columnId, cardId, item)
                    })
                }
                setLoadingAIAnswer(false)
            }
            else {
                setLoadingAIAnswer(false)
                general.notify('Erro ao solicitar resposta da AI', 'error')
            }
        }
        catch (err) {
            console.log(err)
            setLoadingAIAnswer(false)
        }
    }

    const makeCardsWithAI = async (
        columnId: string,
        columnName: string,
        description: string,
        cardsQuantity: number,
    ) => {
        try {
            setLoadingAIAnswer(true)
            let prompt = `
                [noProse]
                Crie uma lista de cards para a seguinte coluna de um kanban:
                Regras: separe por ";", não utilize caracteres especiais, não utilize - ou _, não utilize nada que represente uma lista, não enumere, não faça no padrão Card 1, Card 2, etc
                Descrição do tipo de Card: ${description}
                Coluna: ${columnName}
                Board: ${flow.name}
                Quantidade de Cards: ${cardsQuantity}
            `;
            const response = await general.api.post('helpers/aiprompt', {
                prompt
            })
            if (response.status === 200) {
                if (response.data.status === 403) {
                    setAIAllowed(false)
                    setLoadingAIAnswer(false)
                    general.notify('Você não tem permissão para fazer isso', 'error')
                    return
                }
                if (response.data.data) {
                    let cards = response.data.data.split(';').map((item: string) => item.trim())
                    addMultipleCards(columnId, cards)
                }
                setLoadingAIAnswer(false)
            }
            else {
                setLoadingAIAnswer(false)
                general.notify('Erro ao solicitar resposta da AI', 'error')
            }
        }
        catch (err) {
            console.log(err)
            setLoadingAIAnswer(false)
        }
    }

    useEffect(() => {
        getFlowColumnFromId(false);
    }, [editModalActive]);

    return {
        general,
        flowColumns,
        loading,
        permissionBlock,
        editModalActive,
        deleteModalActive,
        flowcolumnDeleted,
        codeModalActive,
        codeForIntegration,
        flow,
        initialLoad,
        cardCreateRequest,
        columnNoDelay,
        dragOverIndex,
        hoveredColumnId,
        draggedCardId,
        draggedCardIndex,
        draggedFromColumnId,
        addColumnModalActive,
        draggedColumnIndex,
        randomToCloseEverything,
        editFlowModalOpen,
        loadingAIAnswer,
        searchText,
        setSearchText,
        makeChecklistWithAI,
        makeCardsWithAI,
        updateFlow,
        deleteFlow,
        setEditFlowModalOpen,
        setRandomToCloseEverything,
        setDraggedColumnIndex,
        setAddColumnModalActive,
        setDraggedCardIndex,
        setDraggedFromColumnId,
        setDraggedCardId,
        setHoveredColumnId,
        setDragOverIndex,
        setFlowColumnDeleted,
        getFlowColumnFromId,
        setDeleteModalActive,
        setEditModalActive,
        setCodeModalActive,
        setCodeForIntegration,
        setColumnNoDelay,
        setCardCreateRequest,
        createColumn,
        updateColumn,
        deleteColumn,
        deleteAllCardsFromColumn,
        duplicateColumn,
        reorderColumn,
        updateColumnCards,
        moveAllCards,
        addCard,
        deleteCard,
        reorderCard,
        moveCard,
        updateCard,
        duplicateCard,
        addChecklistItem,
        updateChecklistItem,
        deleteChecklistItem
    }
}

export default useController;