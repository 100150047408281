import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import NewItemComponent from '../../../components/app/NewItemComponent';
import CardFlow from '../../../components/app/Cards/CardFlow';
import DefaultCreateModal from '../../../components/app/DefaultCreateModal';
import Pagination from '../../../components/general/Pagination';

const Flows = (props: defaultProps) => {
    const controller = useController();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Fluxos' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            {controller.createModalActive ? <DefaultCreateModal currentItem={controller.setCurrentItem} url={`flow/create`} canAddCustomFields={false} title='Fluxo' type={controller.general.enums.itemTypes.FLOW} item={controller.flowCreateTemplate} modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} /> : null}
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent onSearchChange={controller.setSearchText} pageName='Fluxos' />
                {controller.general.getCompany().status != 'TRIAL' && !controller.general.getCompany().plan.planModules.includes("module_flows") ? <controller.general.NoPermissionComponent permissionDeniedMessage={"O Seu plano não possui acesso a essa funcionalidade, por favor solicite um upgrade"} /> :
                    controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                        <S.operationsArea>
                            <div className='content-cards-wrapper' onClick={() => controller.setCreateModalActive(active => !active)}>
                                <NewItemComponent mainIcon='faTableColumns' pageItem='Fluxos'>
                                    Criar <br /> fluxos
                                </NewItemComponent>
                            </div>
                            {(controller.flows ?? []).map((item: any, index) => {
                                return (
                                    <div className='content-cards-wrapper'key={`flows_${index}`} onClick={() => {
                                        controller.general.GoToRoute(`/app/flow/${item._id}`)
                                    }}>
                                        <CardFlow index={index + 1} title={item.name ?? 'Não informado'} info={[
                                            {
                                                icon: 'faChartSimple',
                                                text: (item.columns?.length ?? 0)
                                            },
                                            {
                                                icon: 'faCube',
                                                text: item.columns?.reduce((total: number, column: any) => total + column.cards.length, 0) ?? 0
                                            },
                                            {
                                                icon: 'faListCheck',
                                                text: (item.columns?.reduce((total: number, column: any) => total + column.cards?.reduce((total: number, card: any) => total + card.checklist?.filter((task: any) => !task.finished_at).length, 0), 0) ?? 0)
                                            }
                                        ]}/>
                                    </div>
                                )
                            })}
                        </S.operationsArea>
                    )}
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    );
}

export default Flows;