import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import EditAccountModal from '../EditAccountModal';

const HeaderBarComponent = (props: any) => {
    const controller = useController();

    return (
        <S.main onMouseLeave={() => controller.setNotificationsVisibility(false)}>
            <EditAccountModal modalActive={controller.showEditAccountModal} setModalActive={controller.setShowEditAccountModal} />
            <S.pageTitle>{props.pageName ?? ''}</S.pageTitle>
            <S.optionsArea>
                <S.searchBar>
                    <S.searchBarInput placeholder={props.pageName ?? 'Pesquisar'} onChange={(e) => {
                        props.onSearchChange && props.onSearchChange(e.target.value.trim())
                    }}
                    onBlur={(e) => {
                        props.onSearchBlur && props.onSearchBlur(e.target.value.trim())
                    }}
                    />
                    <S.searchBarIcon icon={controller.general.icons.faSearch} />
                </S.searchBar>
                <S.notificationsIcon
                    hasnotifications={controller.notifications.length > 0 ? 1 : 0}
                    onClick={() => {
                        controller.setNotificationsVisibility(!controller.notificationsVisibility)
                        controller.getElementPosition();
                    }}
                    ref={controller.notificationRef} onMouseEnter={(e) => {
                        controller.getElementPosition();
                        controller.setNotificationsVisibility(true);
                    }} icon={controller.general.icons.faBell} />
                {controller.notificationsVisibility ?
                    <S.notificationsTab visibility={controller.notificationsVisibility ? 1 : 0} parentX={controller.notifPosition.x} parentY={controller.notifPosition.y}>
                        <S.notificationTabHeader>
                            <S.notificationTabTitle>Notificações</S.notificationTabTitle>
                            <S.notificationTabClear onClick={() => controller.removeAllNotifications()}>Limpar</S.notificationTabClear>
                        </S.notificationTabHeader>
                        {(controller.notifications ?? []).map((item, index) => {
                            return (
                                <S.notification key={index} index={index}>
                                    <S.notificationInfoArea>
                                        <S.notificationTitle>{item.title} <br /> {`${item.createdAt?.split("T")[0]?.split("-")?.reverse()?.join("/") ?? 'Data não informada'} ${item.createdAt?.split("T")[1]?.substring(0, 5) ?? ''}`}</S.notificationTitle>
                                        <S.notificationDescription>{item.message}</S.notificationDescription>
                                    </S.notificationInfoArea>
                                    {item.allow_delete ?
                                        <S.notificationDeleteBtn onClick={() => controller.removeNotification(item._id)}>
                                            <S.notificationDeleteIcon icon={controller.general.icons.faTrash} />
                                        </S.notificationDeleteBtn>
                                        : null
                                    }

                                </S.notification>
                            )
                        })}
                        {controller.notifications.length == 0 ?
                            <S.notificationsTabEmpty>
                                <S.zeroNotificationsIcon icon={controller.general.icons.faBellSlash} />
                                <S.zeroNotifications>Não há notificações!</S.zeroNotifications>
                            </S.notificationsTabEmpty>
                            : null}
                    </S.notificationsTab>
                    : null
                }
                {!controller.account?.avatarUrl ? <S.AvatarComponent onClick={() => controller.setShowEditAccountModal(true)} size={'35px'} textSizeRatio={2.5} name={`${controller.account?.name.split(" ")[0]} ${controller.account?.name.split(" ").length > 1 ? controller.account?.name.split(" ")[controller.account.name.split(" ").length - 1] : ''}`} maxInitials={2} /> : <S.userAvatar onClick={() => controller.setShowEditAccountModal(true)} src={controller.account?.avatarUrl} alt='Imagem do usuário utilizando o sistema' />}
            </S.optionsArea>
        </S.main>
    );
}

export default HeaderBarComponent;