import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import Calendar from '../../../components/app/Calendar';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DefaultCreateModal from '../../../components/app/DefaultCreateModal';
import DefaultEditModal from '../../../components/app/DefaultEditModal';

const Agenda = (props: defaultProps) => {
    const controller = useController();
    /* const localizer = momentLocalizer(moment); */

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Agenda' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            {controller.createModalActive ? <DefaultCreateModal currentItem={controller.setCurrentItem} url={`event/create`} canAddCustomFields={false} title='Evento' type={controller.general.enums.itemTypes.PRODUCT} item={controller.eventCreateTemplate} modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} /> : null}
            {controller.editModalActive ? <DefaultEditModal currentItem={controller.setCurrentItem} url={`event/update/${controller.currentItem?._id}`} canAddCustomFields={false} type={controller.general.enums.itemTypes.PRODUCT} item={controller.currentItem} modalActive={controller.editModalActive} setModalActive={controller.setEditModalActive} /> : null}
            <S.showMoreEventsModal active={controller.showMoreEventsModal} onClick={() => controller.setShowMoreEventsModal(false)}>
                <S.showMoreEventsModalContent onClick={(e) => e.stopPropagation()}>
                    <S.showMoreEventsModalHeader>
                        <S.showMoreEventsModalTitle>Mais eventos</S.showMoreEventsModalTitle>
                        <S.showMoreEventsModalClose onClick={() => controller.setShowMoreEventsModal(false)} icon={controller.general.icons.faTimes} />
                    </S.showMoreEventsModalHeader>
                    <S.showMoreEventsModalBody>
                        {controller.eventsToShowMore.map((event: any, index: number) => {
                            return (
                                <S.showMoreEvent key={index} onMouseDown={() => {
                                    controller.setCurrentItem(event);
                                    controller.setEditModalActive(true);
                                    controller.setShowMoreEventsModal(false);
                                }}>
                                    <S.showMoreEventTitle>{event.title}</S.showMoreEventTitle>
                                    <S.showMoreEventTime>Horário: {`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}</S.showMoreEventTime>
                                    <S.showMoreEventDescription>Descrição: {event.description}</S.showMoreEventDescription>
                                </S.showMoreEvent>
                            );
                        })}
                    </S.showMoreEventsModalBody>
                </S.showMoreEventsModalContent>
            </S.showMoreEventsModal>
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent onSearchChange={() => controller.general.notify('Não é possível buscar na página', 'warning')} pageName='Agenda' />
                {controller.general.getCompany().status != 'TRIAL' && !controller.general.getCompany().plan.planModules.includes("module_schedule") ? <controller.general.NoPermissionComponent permissionDeniedMessage={"O Seu plano não possui acesso a essa funcionalidade, por favor solicite um upgrade"} /> :
                    controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                        <>
                            <S.createTaskButton onClick={() => controller.setCreateModalActive(true)}>{'criar evento'.toUpperCase()}</S.createTaskButton>
                            <S.operationsArea>
                                <S.calendarWrapper>
                                    <controller.general.DefaultLoadingFit active={controller.loading} />
                                    <Calendar
                                        height={{
                                            tablet: '45%'
                                        }}
                                        eventDays={
                                            [...controller.events, ...controller.holidays].map((event: any) => {
                                                return moment(event.start).format('DD/MM/YYYY');
                                            })
                                        }
                                        changeMonth={controller.changeMonth}
                                        onCellClick={controller.cellClick}
                                        headerClick={() => { controller.setSelectedDay(null) }}
                                        position={'relative'}
                                        calendarType={'date'}
                                    />
                                    <S.calendarList>
                                        <S.calendarListHeader>
                                            <S.calendarListTitle key={controller.selectedDay}>Eventos {controller.selectedDay ? moment(controller.selectedDay).tz('America/Sao_Paulo').format('DD/MM/YYYY') : moment(`${controller.currentYear}-${controller.currentMonth}`).tz('America/Sao_Paulo').format('MM/YYYY')}</S.calendarListTitle>
                                        </S.calendarListHeader>
                                        <S.calendarListBody>
                                            {
                                                [...controller.events, ...controller.holidays].filter((date: any) => {
                                                    if (controller.selectedDay) {
                                                        return moment(date.start).tz('America/Sao_Paulo').format('DD/MM/YYYY') == moment(controller.selectedDay).tz('America/Sao_Paulo').format('DD/MM/YYYY');
                                                    } else {
                                                        return moment(date.start).tz('America/Sao_Paulo').format('MM/YYYY') == moment(`${controller.currentYear}-${controller.currentMonth}`).tz('America/Sao_Paulo').format('MM/YYYY');
                                                    }
                                                }).length > 0 ? (
                                                    [...controller.events, ...controller.holidays].filter((date: any) => {
                                                        if (controller.selectedDay) {
                                                            return moment(date.start).tz('America/Sao_Paulo').format('DD/MM/YYYY') == moment(controller.selectedDay).tz('America/Sao_Paulo').format('DD/MM/YYYY');
                                                        } else {
                                                            return moment(date.start).tz('America/Sao_Paulo').format('MM/YYYY') == moment(`${controller.currentYear}-${controller.currentMonth}`).tz('America/Sao_Paulo').format('MM/YYYY');
                                                        }
                                                    })
                                                    .sort((a: any, b: any) => {
                                                        return moment(a.start).tz('America/Sao_Paulo').isBefore(moment(b.start).tz('America/Sao_Paulo')) ? -1 : 1;
                                                    })
                                                    .map((event: any, index: number) => {
                                                        return (
                                                            <S.calendarListItem index={index} key={index} onClick={() => {
                                                                if (event.title.includes('Feriado')) {
                                                                    controller.general.notify('Esse evento é um feriado, não é possível editá-lo', 'info');
                                                                    return;
                                                                };
                                                                controller.setCurrentItem(event);
                                                                controller.setEditModalActive(true);
                                                            }}>
                                                                <S.calendarListItemTitle>{event.title}</S.calendarListItemTitle>
                                                                <S.calendarListItemTime>{`Dia ${moment(event.start).tz('America/Sao_Paulo').format('DD')}: ${moment(event.start).tz('America/Sao_Paulo').format('HH:mm')} - ${event.title.includes('Feriado') ? '23:59' : moment(event.end).tz('America/Sao_Paulo').format('HH:mm')}`}</S.calendarListItemTime>
                                                                <S.calendarListItemDescription>Descrição: {event.description ? event.description : 'Não informada'}</S.calendarListItemDescription>
                                                            </S.calendarListItem>
                                                        );
                                                    })
                                                ) : (
                                                    <S.noEvents>
                                                        <S.noEventsIcon icon={controller.general.icons.faCalendarXmark} />
                                                        <S.noEventsText>Nenhum evento <br /> encontrado</S.noEventsText>
                                                    </S.noEvents>
                                                )
                                            }

                                        </S.calendarListBody>
                                    </S.calendarList>
                                </S.calendarWrapper>
                            </S.operationsArea>
                        </>
                    )}
            </S.generalArea>
        </S.main>
    );
}

export default Agenda;