import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import ActionCard from '../../../components/app/Cards/ActionCard';
import UserCard from '../../../components/app/Cards/UserCard';
import DefaultEditModal from '../../../components/app/DefaultEditModal';
import DefaultCreateModal from '../../../components/app/DefaultCreateModal';
import Pagination from '../../../components/general/Pagination';

const HumanResources = (props: defaultProps) => {
    const controller = useController();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Colaboradores' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            {controller.collaboratorPermissionsModal ?
                <S.collaboratorPermissionsModalWrapper onClick={() => controller.setCollaboratorPermissionsModal(false)}>
                    <S.collaboratorPermissionsModal onClick={(e: any) => e.stopPropagation()}>
                        <S.permissionsModalHeader>
                            <S.permissionsModalTitle>Permissões do Colaborador</S.permissionsModalTitle>
                            <S.permissionsModalClose onClick={() => controller.setCollaboratorPermissionsModal(false)} icon={controller.general.icons.faTimes} />
                        </S.permissionsModalHeader>
                        <S.permissionsModalContent>
                            {controller.collaboratorPermissions.map((permission: any, index: number) => {
                                return (
                                    <S.permissionsModalItem key={`permission_${index}`}>
                                        <S.permissionsModalItemTitle>{permission?.name}</S.permissionsModalItemTitle>
                                        <S.permissionsModalItemPermissions>
                                            {permission.permissions.map((subPermission: any, index: number) => {
                                                return (
                                                    <S.permissionsModalItemPermission key={`sub_permission_${index}`}>
                                                        <S.permissionCheckboxWrapper checked={controller.currentItem?.permissions?.includes(`${permission.key}_${subPermission.key}`)}>
                                                            <S.permissionCheckbox type='checkbox' id={`permission_${permission.key}_${subPermission.key}`} defaultChecked={controller.currentItem?.permissions?.includes(`${permission.key}_${subPermission.key}`)} onChange={() => controller.handlePermissionChange(`${permission.key}_${subPermission.key}`)} />
                                                            <S.permissionCheckboxLabel checked={controller.currentItem?.permissions?.includes(`${permission.key}_${subPermission.key}`)} htmlFor={`permission_${permission.key}_${subPermission.key}`}>{permission.key == 'general' ? subPermission.name : `${subPermission.name} ${permission.name}`}</S.permissionCheckboxLabel>
                                                        </S.permissionCheckboxWrapper>
                                                    </S.permissionsModalItemPermission>
                                                )
                                            })}
                                        </S.permissionsModalItemPermissions>
                                    </S.permissionsModalItem>
                                )
                            })}
                        </S.permissionsModalContent>
                        <S.saveButton onClick={() => controller.updateCollaborator()}>Salvar</S.saveButton>
                    </S.collaboratorPermissionsModal>
                </S.collaboratorPermissionsModalWrapper>
                : null}
            <controller.general.DefaultLoading active={controller.loading} />
            {controller.createModalActive ? <DefaultCreateModal currentItem={controller.setCurrentItem} url={`collaborator/create`} canAddCustomFields title='Colaborador' type={controller.general.enums.itemTypes.EMPLOYEE} item={controller.personCreateTemplate} modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} /> : null}
            {controller.editModalActive ? <DefaultEditModal options={[
                {
                    name: 'Permissões', function: () => {
                        controller.setCollaboratorPermissionsModal(true)
                        controller.setEditModalActive(false)
                    }
                }
            ]} currentItem={controller.setCurrentItem} url={`collaborator/update/${controller.currentItem?._id}`} canAddCustomFields type={controller.general.enums.itemTypes.EMPLOYEE} item={controller.currentItem} modalActive={controller.editModalActive} setModalActive={controller.setEditModalActive} hasTimeline={true} /> : null}
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent onSearchChange={controller.setSearchText} pageName='Colaboradores' />
                {controller.general.getCompany().status != 'TRIAL' && !controller.general.getCompany().plan.planModules.includes("module_human_resources") ? <controller.general.NoPermissionComponent permissionDeniedMessage={"O Seu plano não possui acesso a essa funcionalidade, por favor solicite um upgrade"} /> :
                    controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                        <S.operationsArea>
                            <div className='content-cards-wrapper' onClick={() => controller.setCreateModalActive(active => !active)}>
                                <ActionCard mainIcon='faUsers' pageItem='Colaborador'>
                                    Adicionar <br /> colaborador
                                </ActionCard>
                            </div>
                            {(controller.collaborators ?? []).map((item: any, index) => {
                                if (controller.general?.getAccount()?.email == item.infos.filter((info: any) => info.key == 'email')[0]?.value) return (
                                    <div className='content-cards-wrapper' onClick={() => {
                                        controller.general.notify('Você não pode editar seu próprio registro', 'error')
                                    }} key={`hr_${index}`}>
                                        <UserCard index={index + 1} title={item.infos.filter((info: any) => info.key == 'name')[0]?.value ?? 'Não informado'} subtitle={item.infos.filter((info: any) => info.key == 'job_title')[0]?.value ?? 'Não informado'} infos={item.infos} />
                                    </div>
                                );
                                return (
                                    <div className='content-cards-wrapper' onClick={() => {
                                        const itemsFilled = [...controller.personCreateTemplate.infos]
                                        itemsFilled.forEach((itemFilled) => {
                                            itemFilled.value = item[itemFilled.key]
                                            if (itemFilled.type === 'money') itemFilled.value = controller.general.formatMoney((itemFilled.value ?? '').toString())
                                        })
                                        item.infos.map((info: any) => {
                                            if (itemsFilled.filter((itemFilled: any) => itemFilled.key == info.key).length == 0) {
                                                itemsFilled.push(info)
                                            }
                                        })
                                        controller.setCurrentItem({ ...item, infos: itemsFilled })
                                        controller.setEditModalActive(active => !active)
                                    }} key={`hr_${index}`}>
                                        <UserCard index={index + 1} title={item.infos.filter((info: any) => info.key == 'name')[0]?.value ?? 'Não informado'} subtitle={item.infos.filter((info: any) => info.key == 'job_title')[0]?.value ?? 'Não informado'} infos={item.infos} />
                                    </div>
                                )
                            })}
                        </S.operationsArea>
                    )}
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    );
}

export default HumanResources;